<template>
  <v-card color="grey" :height="height" width="200" tile>
    <!-- <draggable v-model="slots" @start="drag = true" @end="drag = false"> -->

    <template v-for="slot in bookings">
      <booking-slot
        :height="slot.height"
        :class="`pa-2 text-center ${slot.index}`"
        :key="slot.index"
        :status="slot.status"
        :color="slot.color"
        :name="slot.event ? slot.event.name : ''"
        :events="slot.event"
        @click="onClickSlot(slot)"
        @gotoParticipants="getParticipants(slot)"
      >
      </booking-slot>
      <v-divider :key="`d_${slot.index}`"></v-divider>
    </template>
    <!-- </draggable> -->
  </v-card>
</template>

<script>
// import draggable from "vuedraggable";
import BookingSlot from "./EventBookingSlot.vue";

export default {
  props: {
    bookings: { type: Array, default: () => [] },
    increment: { type: Number, default: 60 },
    height: { type: Number, default: 1000 },
    name: { type: String },
    id: { type: Number },
    date: { type: String },
  },
  components: {
    // draggable,
    BookingSlot,
  },
  data() {
    return {};
  },
  methods: {
    onClickSlot(data) {
      // console.log("data");
      // console.log(data);
      if (data.status != "null_space") {
        this.$emit("open-booking-form", data);
      }
    },
    getParticipants(data) {
      if (data.status != "null_space") {
        this.$emit("open-participans-model", data);
      }
    },
  },
};
</script>

<style></style>
