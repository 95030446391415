<template>
  <v-hover v-slot="{ hover }">
    <v-sheet
      block
      :height="height"
      @click.self="onClick"
      :elevation="
        hover && status != 'not_available' ? (status == 'null_space' ? 0 : 12) : 0
      "
      :class="`d-flex justify-center align-center cursorstyle ${status} ${
        hover && status != 'not_available' ? 'hoverstyle' : ''
      }`"
      :style="{ background: color }"
    >
      <template slot></template>

      <div v-if="status == 'event'">
        <v-col @click="onClick" md="12" sm="12" lg="12" style="padding: 5px"
          ><h2
            class="ellipsis-card"
            style="max-width: 100px; padding-top: 5px; font-size: 11px"
            :title="name"
          >
            {{ name }}
          </h2>
        </v-col>
        <v-row no-gutters style="margin-bottom: 20px">
          <v-col
            :md="height > 50 ? 12 : 3"
            :sm="height > 50 ? 12 : 3"
            :lg="height > 50 ? 12 : 3"
          >
            <v-btn
              :disabled="parseInt(events.participants) >= parseInt(events.total_tickets)"
              @click="onClick"
              dark
              :class="height <= 50 ? 'text_field1' : ''"
              outlined
              block
              x-small
            >
              <v-icon x-small>mdi-plus</v-icon>
              <span v-if="height > 50">Participant</span>
            </v-btn>
          </v-col>
          <v-col
            :md="height > 50 ? 12 : 9"
            :sm="height > 50 ? 12 : 9"
            :lg="height > 50 ? 12 : 9"
            :style="{ 'margin-top': height > 50 ? '10px' : '0px;' }"
          >
            <v-btn
              style="padding: 0px"
              @click="$emit('gotoParticipants')"
              dark
              x-small
              outlined
              block
              :class="height <= 50 ? 'text_field2' : ''"
              >participants : {{ events.participants ? events.participants : 0 }}/{{
                events.total_tickets
              }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-hover>
</template>

<script>
export default {
  props: {
    id: { type: Number },
    status: { type: String, default: "available" },
    draggable: { type: Boolean, default: false },
    height: { type: Number, default: 50 },
    name: { type: String, default: "NA" },
    events: { type: Object, default: null },
    color: { type: String },
  },
  data() {
    return {
      textColor: "blue",
    };
  },
  methods: {
    onClick() {
      if (this.status != "not_available" && this.status != "completed") {
        this.$emit("click");
      }
    },
  },
};
</script>

<style scoped>
.paid {
  background: rgb(0, 89, 118) !important;
  color: #fff;
  cursor: pointer;
}
.unpaid {
  background: rgb(206, 168, 0) !important;
  color: #fff;
  cursor: pointer;
}
.available {
  cursor: pointer;
}
.not_available {
  cursor: not-allowed;
  background: #bfbfbf !important;
}
.hoverstyle {
  border: 2px solid teal;
}
.available .v-btn {
  margin: 6px 8px;
  width: 20px;
  height: 20px;
}
.maintenance {
  background: rgb(150, 26, 4) !important;
  color: #fff;
  cursor: not-allowed;
}
.trainer {
  background: rgb(21, 114, 2) !important;
  color: #fff;
  cursor: pointer;
}
.completed {
  background: #e0f4f4;
}
.null_space {
  background: #e0f4f4 !important;
  cursor: not-allowed !important ;
  border: 0 solid #e0f4f4;
}
.event {
  background: rgb(0, 89, 118) !important;
  border: 2px solid rgb(0, 89, 118);
  color: #fff;
  cursor: pointer;
}
.work_shop {
  color: rgb(7, 0, 0);
  cursor: pointer;
}
.event_time {
  background: #4477b9 !important;
  cursor: not-allowed !important ;
  border: 0 solid #e0f4f4;
}
</style>
