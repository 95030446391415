<template>
  <v-container fluid>
    <v-row
      justify="center"
      v-if="
        checkReadPermission($modules.facility.schedule.slug) ||
          checkReadPermission($modules.events.schedule.slug)
      "
      no-gutters
      class="mt-3 pb-4 scheduleSelection"
    >
      <v-hover v-slot:default="{ hover }">
        <v-btn
          icon
          :elevation="hover ? 2 : 0"
          :color="hover ? 'teal' : 'grey'"
          @click="getEventSchedule"
        >
          <v-icon>mdi-refresh-circle</v-icon>
        </v-btn>
      </v-hover>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-col
        md="2"
        justify="center"
        v-if="
          checkReadPermission($modules.facility.schedule.slug) &&
            checkReadPermission($modules.events.schedule.slug)
        "
      >
        <router-link :to="`/schedule`">
          <v-btn block light tile>Facility</v-btn>
        </router-link>
      </v-col>
      <v-col
        md="2"
        justify="center"
        v-if="
          checkReadPermission($modules.facility.schedule.slug) &&
            checkReadPermission($modules.events.schedule.slug)
        "
      >
        <router-link :to="`/event-schedule`">
          <v-btn block dark color="#062b48" tile>Events</v-btn>
        </router-link>
      </v-col>
      <!-- <v-col
        md="2"
        justify="center"
        v-if="checkReadPermission($modules.workshops.schedule.slug)"
      >
        <router-link :to="`/workshop-schedule`">
          <v-btn block light tile>Workshop</v-btn>
        </router-link>
      </v-col> -->
      <v-spacer></v-spacer>
      <v-col md="2" class="text-lg-right mr-2">
        <v-select
          v-if="
            checkReadPermission($modules.facility.schedule.slug) &&
              checkReadPermission($modules.events.schedule.slug)
          "
          :items="venueServices"
          v-model="venueService"
          item-value="venue_service_id"
          item-text="name"
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          return-object
          background-color="#fff"
          dense
          @change="initializeScheduleForVenueService()"
        ></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="4">
        <router-link :to="`/event-schedule`">
          <v-btn color="#062b48" dark tile>Day</v-btn>
        </router-link>
        <v-btn @click="gotoEventCalendar" light tile>Month</v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col sm="4">
        <v-row no-gutters>
          <v-col sm="1" class="text-lg-center mr-1">
            <v-btn
              fab
              dark
              x-small
              color="teal"
              :disabled="!venueService.venue_service_id"
              @click="prevDate"
            >
              <v-icon dark>mdi-menu-left</v-icon>
            </v-btn>
          </v-col>
          <v-col sm="8" class="text-lg-center">
            <date-field
              v-model="date"
              :button="true"
              :disabled="!venueService.venue_service_id"
              @change="getEventSchedule"
            >
            </date-field>
          </v-col>
          <v-col sm="1" class="text-lg-center ml-1">
            <v-btn
              fab
              dark
              x-small
              color="teal"
              :disabled="!venueService.venue_service_id"
              @click="nextDate"
            >
              <v-icon dark>mdi-menu-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col sm="4" class="text-lg-right">
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <!-- <v-col
            v-if="
              !checkReadPermission($modules.facility.schedule.slug) &&
              checkReadPermission($modules.events.schedule.slug)
            "
            sm="5"
            class="text-lg-right mr-2"
          >
            <v-select
              :items="venueServices"
              v-model="venueService"
              item-value="venue_service_id"
              item-text="name"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              background-color="#fff"
              dense
              return-object
              @change="changeCategory()"
            ></v-select>
          </v-col> -->

          <v-col
            :sm="!checkReadPermission($modules.events.schedule.slug) ? 5 : 6"
            class="text-lg-right mr-2"
          >
          </v-col>
          <v-col md="4" class="text-lg-right mr-2">
            <v-select
              v-if="
                !checkReadPermission($modules.facility.schedule.slug) &&
                  checkReadPermission($modules.events.schedule.slug)
              "
              :items="venueServices"
              v-model="venueService"
              item-value="venue_service_id"
              item-text="name"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              return-object
              background-color="#fff"
              dense
              @change="initializeScheduleForVenueService()"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="d-flex mt-5">
      <header-cell name="Time"></header-cell>
      <template v-for="facility in facilities">
        <header-cell
          :key="`h_${facility.name}`"
          :height="height"
          :name="facility.name"
          :formation="facility.game_formations"
          :tooltip="!facility.id ? facility.location : null"
        ></header-cell>
      </template>
    </div>

    <div
      v-resize="onResize"
      class="d-flex overflow-y-auto grey--text text--lighten-2 caption"
      :style="
        `min-height: ${scheduleHeight}px; max-height: ${scheduleHeight}px`
      "
    >
      <time-column :height="height" :increment="increment"></time-column>
      <template v-for="(facility, index) in facilities">
        <event-column
          :key="`h_${facility.name}`"
          :height="height"
          @open-booking-form="openBooking"
          @open-participans-model="openParticipants"
          :name="facility.name"
          :capacity="facility.capacity"
          :perCapacity="perCapacity"
          :id="facility.id"
          :bookings="getSlotData(index)"
        >
        </event-column>
      </template>
    </div>

    <event-booking-form
      v-bind="eventForm"
      @close="eventForm.showeventForm = false"
      @booked="completeOrder"
      @cancel="getEventSchedule"
      @pay="openOrderCloseBooking"
      :increment="increment"
    ></event-booking-form>

    <order-details
      :id="orderId"
      @close="orderId = null"
      @paymentDone="getEventSchedule"
    ></order-details>
    <open-participans
      v-bind="participans"
      @close="participans.show = false"
      @reciept="getOrderDetails"
      @editEvent="editEvent"
      @refresh="getEventSchedule"
    >
    </open-participans>
  </v-container>
</template>

<script>
import TimeColumn from "@/components/Schedule/TimeColumn";
import EventColumn from "@/components/Schedule/Event/EventColumn";
import HeaderCell from "@/components/Schedule/HeaderCell";
import OrderDetails from "@/components/Order/OrderDetails";
import EventBookingForm from "./EventBookingFormNew.vue";
import openParticipans from "./EventParticipans.vue";

import moment from "moment";
export default {
  components: {
    EventColumn,
    TimeColumn,
    HeaderCell,
    OrderDetails,
    EventBookingForm,
    openParticipans,
  },
  data() {
    return {
      drag: false,
      facilities: [],
      eventForm: {},
      date: moment().format("YYYY-MM-DD"),
      venueService: {},
      gameFormationFilter: [],
      increment: 60,
      height: 1200,
      perCapacity: 0,
      orderId: null,
      participant: {},
      minBookingTime: 60,
      scheduleHeight: 500,
      scheduleData: [],
      eventSchedule: [],
      participans: {},
      currentEvent: {},
    };
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.showLoader("Loading");
      this.$store.dispatch("loadVenueServices").then(() => {
        this.$nextTick(() => {
          if (this.$store.getters.getVenueServices.data.length) {
            this.venueService = this.$store.getters.getVenueServices.data[0];
            this.getRouteParams();
            this.initializeScheduleForVenueService();
          }
          this.hideLoader();
        });
      });
    } else {
      if (this.$store.getters.getVenueServices.data.length) {
        this.venueService = this.$store.getters.getVenueServices.data[0];
        this.getRouteParams();
        this.initializeScheduleForVenueService();
      }
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter((service) => service.name != "POS");
    },
    venueServiceConfiguration() {
      return this.$store.getters.getConfigurationByVenueServiceId(
        this.venueService.venue_service_id
      );
    },
  },
  methods: {
    getRouteParams() {
      if (this.$route.params.data) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueService = data.venue_service;
        this.date = data.date;
      }
    },
    initializeScheduleForVenueService() {
      if (this.venueService.venue_service_id) {
        this.increment = this.venueService.time_increment;
        this.height = this.height = (60 / this.increment) * 1200;
        if (
          !this.$store.getters.getConfigurationStatus(
            this.venueService.venue_service_id
          )
        ) {
          this.$store.dispatch(
            "loadConfigurationsByVenueServiceId",
            this.venueService.venue_service_id
          );
        }
        this.getEventSchedule();
      }
    },
    onResize() {
      this.scheduleHeight = window.innerHeight - 350;
    },
    gameFormations() {
      return this.$store.getters.getGameFormationsByVenueServiceId(
        this.venueService.venue_service_id
      );
    },
    openBooking(data) {
      if (
        parseInt(data.event.participants) == parseInt(data.event.total_tickets)
      )
        this.showError("Ticket is not available");
      else this.openeventForm(data.event);
    },

    openeventForm(data) {
      if (data.seat_map_id) {
        this.$router.push({
          name: "EventsView",
          params: { data: btoa(data.id) },
        });
      } else {
        this.eventForm = {
          showeventForm: true,
          start_time: moment(data.start_time, "hh:mm a").format("HH:mm:ss"),
          end_time: moment(data.end_time, "hh:mm a").format("HH:mm:ss"),
          start_date: data.start_date,
          end_date: data.end_date,
          event_name: data.name,
          facility_id: data.facility_id,
          event_id: data.id,
          date: this.date,
          venue_service_id: this.venueService.venue_service_id,
          service: this.venueService.name,
          id: data.id != null ? data.id : 0,
          event_type_id: data.event_type_id,
          image_path: data.image_path,
          location: data.location,
          participants: data.participants ? parseInt(data.participants) : 0,
          sales: data.sales ? data.sales : 0,
        };
      }
    },

    editEvent(data) {
      this.participans.show = false;
      this.eventForm = {
        showeventForm: true,
        start_time: moment(this.currentEvent.start_time, "hh:mm a").format(
          "HH:mm:ss"
        ),
        end_time: moment(this.currentEvent.end_time, "hh:mm a").format(
          "HH:mm:ss"
        ),
        start_date: this.currentEvent.start_date,
        end_date: this.currentEvent.end_date,
        event_name: this.currentEvent.name,
        facility_id: this.currentEvent.facility_id,
        event_id: this.currentEvent.id,
        date: this.date,
        venue_service_id: this.venueService.venue_service_id,
        service: this.venueService.name,
        id: this.currentEvent.id != null ? this.currentEvent.id : 0,
        event_type_id: this.currentEvent.event_type_id,
        image_path: this.currentEvent.image_path,
        location: this.currentEvent.location,
        order_id: data.order_id,
        participants: data.participants,
        sales: data.sales,
      };
    },

    openParticipants(data) {
      this.currentEvent = data.event;
      this.participans = {
        show: true,
        event_id: data.event.id,
        event_name: this.currentEvent.name,
        date: this.date,
      };
    },

    getEventSchedule() {
      this.showLoader("Loading");
      let url = `?venue_service_id=${this.venueService.venue_service_id}&date=${this.date}`;
      this.$http
        .get("venues/events/schedule" + url)
        .then((response) => {
          if (response.data.status == true) {
            const data = response.data.data;
            this.facilities = data.filter((x) => x.events.length != 0);
            this.eventSchedule = [];
            const totalDayMinutes = 1440;
            const divisions = totalDayMinutes / this.increment;
            let cellHeight = this.height / divisions;
            let facilityData = [];
            this.facilities.forEach((facility, fi) => {
              facilityData[fi] = {};
              facility.events.forEach((event) => {
                if (event.event_schedules) {
                  event.event_schedules.forEach((eventSchedule) => {
                    let eventData = this.parseTimeData(eventSchedule);
                    if (
                      moment(this.date, "YYYY-MM-DD") <
                      moment(eventSchedule.end_date, "YYYY-MM-DD")
                    ) {
                      if (
                        moment(this.date, "YYYY-MM-DD") >
                        moment(eventSchedule.start_date, "YYYY-MM-DD")
                      ) {
                        eventData = this.parseTimeAllDay(eventSchedule);
                      } else {
                        eventData = this.parseTimeTillEndOfDay(eventSchedule);
                      }
                    }
                    if (
                      moment(this.date, "YYYY-MM-DD") >
                      moment(eventSchedule.start_date, "YYYY-MM-DD")
                    ) {
                      if (
                        moment(this.date, "YYYY-MM-DD") <
                        moment(eventSchedule.end_date, "YYYY-MM-DD")
                      ) {
                        eventData = this.parseTimeAllDay(eventSchedule);
                      } else {
                        eventData = this.parseTimeFromStartOfDay(eventSchedule);
                      }
                    }

                    eventData.event = event;
                    eventData.event.start_date = eventData.start_date;
                    eventData.event.end_date = eventData.end_date;
                    eventData.event.start_time = eventData.start_time;
                    eventData.event.end_time = eventData.end_time;
                    facilityData[fi][eventData.startIndex] = eventData;
                  });
                }
              });
              let index = 0;
              let nextIndex = index + this.increment;

              this.eventSchedule[fi] = [];
              while (index < totalDayMinutes) {
                nextIndex = index + this.increment;
                if (facilityData[fi][index]) {
                  let eventData = facilityData[fi][index];
                  let numberof = Math.round(
                    (eventData.endIndex - eventData.startIndex) / this.increment
                  );

                  let eventHeight = numberof * cellHeight;
                  eventData.index = index;
                  eventData.height = eventHeight + (numberof - 1);
                  eventData.status = "event";
                  this.eventSchedule[fi].push(eventData);
                  index = eventData.endIndex;
                } else {
                  const element = {
                    index: index,
                    start_time: moment()
                      .startOf("day")
                      .add(index, "minutes")
                      .format("hh:mm a"),
                    end_time: moment()
                      .startOf("day")
                      .add(nextIndex, "minutes")
                      .format("hh:mm a"),
                    height: cellHeight,
                    status: "null_space",
                  };
                  this.eventSchedule[fi].push(element);
                  index = nextIndex;
                }
              }
            });
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    parseTimeData(element) {
      let startTime = moment(element.start_time, "HH:mm:ss");
      let endTime = moment(element.end_time, "HH:mm:ss");
      let startIndex = startTime.hours() * 60 + startTime.minutes();
      let endIndex = endTime.hours() * 60 + endTime.minutes();
      endIndex = endIndex + 1 == 1440 ? 1440 : endIndex;
      return {
        startIndex: startIndex,
        endIndex: endIndex,
        event: element,
        start_time: startTime.format("hh:mm a"),
        end_time: endTime.format("hh:mm a"),
      };
    },

    parseTimeTillEndOfDay(element) {
      let startTime = moment(element.start_time, "HH:mm:ss");
      let endTime = moment(element.end_time, "HH:mm:ss").endOf("day");
      let startIndex = startTime.hours() * 60 + startTime.minutes();
      let endIndex = endTime.hours() * 60 + endTime.minutes();
      endIndex = endIndex + 1 == 1440 ? 1440 : endIndex;
      return {
        startIndex: startIndex,
        endIndex: endIndex,
        event: element,
        start_time: startTime.format("hh:mm a"),
        end_time: endTime.format("hh:mm a"),
      };
    },

    parseTimeFromStartOfDay(element) {
      let startTime = moment(element.start_time, "HH:mm:ss").startOf("day");
      let endTime = moment(element.end_time, "HH:mm:ss");
      let startIndex = startTime.hours() * 60 + startTime.minutes();
      let endIndex = endTime.hours() * 60 + endTime.minutes();
      endIndex = endIndex + 1 == 1440 ? 1440 : endIndex;
      return {
        startIndex: startIndex,
        endIndex: endIndex,
        event: element,
        start_time: startTime.format("hh:mm a"),
        end_time: endTime.format("hh:mm a"),
      };
    },

    parseTimeAllDay(element) {
      let startTime = moment(element.start_time, "HH:mm:ss").startOf("day");
      let endTime = moment(element.end_time, "HH:mm:ss").endOf("day");
      let startIndex = startTime.hours() * 60 + startTime.minutes();
      let endIndex = endTime.hours() * 60 + endTime.minutes();
      endIndex = endIndex + 1 == 1440 ? 1440 : endIndex;
      return {
        startIndex: startIndex,
        endIndex: endIndex,
        event: element,
        start_time: startTime.format("hh:mm a"),
        end_time: endTime.format("hh:mm a"),
      };
    },
    nextDate() {
      this.date = moment(this.date)
        .add(1, "days")
        .format("YYYY-MM-DD");
      this.getEventSchedule();
    },
    prevDate() {
      this.date = moment(this.date)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      this.getEventSchedule();
    },
    getSlotData(index) {
      return this.eventSchedule[index];
    },

    getOrderDetails(orderId) {
      this.participans.show = false;
      this.orderId = orderId;
    },

    openOrderCloseBooking(orderId) {
      this.eventForm.showeventForm = false;
      this.orderId = orderId;
      this.currentEvent = {};
      this.getEventSchedule();
    },
    completeOrder(orderId) {
      // this.getEventSchedule();
      this.openOrderCloseBooking(orderId);
    },
    gotoEventCalendar() {
      this.$router.push({
        name: "EventCalendarWithParams",
        params: {
          data: btoa(
            JSON.stringify({
              venue_service: this.venueService,
              date: this.date,
            })
          ),
        },
      });
    },
  },
};
</script>

<style></style>
