<template>
  <div>
    <v-dialog v-model="show" width="60%" scrollable persistent>
      <v-card>
        <v-toolbar color="teal" dark>
          <v-toolbar-title>Participants</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-list subheader v-if="participantsDetails.length > 0">
            <v-list-item
              v-for="(participant, index) in participantsDetails"
              :key="participant.id"
            >
              <v-list-item-avatar>
                <view-image
                  defaultImage="user"
                  :image="participant.profile_image"
                ></view-image>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >{{ participant.first_name }}
                  {{ participant.last_name }}</v-list-item-title
                >
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  class="ma-2 white--text teal-color"
                  text
                  @click="viewTicket(index)"
                >
                  Tickets
                </v-btn>
              </v-list-item-action>

              <v-list-item-action>
                <v-btn
                  class="ma-2 white--text teal-color"
                  text
                  @click="viewAdditionalParticipants(participant.order_id)"
                >
                  Additional participants
                </v-btn>
              </v-list-item-action>
              <v-list-item-action style="margin-left: 0px">
                <v-btn
                  v-show="
                    participant.check_in_time == null ||
                      participant.check_out_time == null
                  "
                  :color="
                    participant.check_in_time == null ? 'success' : 'error'
                  "
                  @click="checkInAndOut(participant.event_booking_id)"
                  class="ma-1 white--text teal-color"
                >
                  {{
                    "Check " +
                      (participant.check_in_time == null ? "In" : "Out")
                  }}</v-btn
                >
              </v-list-item-action>
              <v-list-item-action v-if="participant.status == 'Unpaid'">
                <v-btn
                  class="ma-2 white--text teal-color"
                  text
                  @click="$emit('editEvent', participant)"
                >
                  Edit
                </v-btn>
              </v-list-item-action>
              <v-list-item-action v-if="participant.status == 'Unpaid'">
                <v-btn
                  class="ma-2 white--text red-color"
                  @click="cancelEvent(participant.order_id)"
                  >Cancel
                </v-btn>
              </v-list-item-action>
              <v-list-item-action v-if="participant.status == 'Unpaid'">
                <v-btn
                  class="ml-2 white--text teal-color"
                  @click="$emit('reciept', participant.order_id)"
                  >Pay
                </v-btn>
              </v-list-item-action>
              <v-list-item-action v-else>
                <v-btn
                  class="ml-2 white--text blue-color"
                  @click="$emit('reciept', participant.order_id)"
                  >Receipt
                </v-btn>
              </v-list-item-action>
              <v-list-item-action
                v-if="
                  participant.alert_notes != null &&
                    participant.alert_notes != ''
                "
              >
                <v-btn
                  class="ma-1 white--text red-color"
                  @click="openAlert(participant.customer_id)"
                >
                  Alert
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div
            v-else
            style="height: 200px"
            class="d-flex justify-center align-center"
          >
            No Participants
          </div>
          <v-divider></v-divider>
        </v-card-text>
        <v-pagination
          class="mt-3"
          v-model="page"
          :length="totalPages"
        ></v-pagination>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('close')"
            class="ma-2 white--text blue-color"
            text
            >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
    <customer-alert-note
      v-bind="alertNote"
      :id="alertNote.id"
      :note="alertNote.note"
      @clearAlertNote="clearAlertNote"
      @closeAlert="closeAlert"
    >
    </customer-alert-note>
    <ticket-model v-bind="tickets" @close="tickets = { id: null }">
      <template v-slot:carosal v-if="this.ticketData.length > 1">
        <v-btn
          fab
          absolute
          right
          x-small
          color="blue"
          dark
          class="mt-4"
          @click="changeTickets('next')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          fab
          absolute
          left
          x-small
          color="blue"
          dark
          class="mt-4"
          @click="changeTickets('prev')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </template>
    </ticket-model>
    <event-addons
      v-bind="openAddonModal"
      :addOnParticipants="addOnParticipants"
      @close="closeAddonModal"
      @refreshModal="viewAdditionalParticipants"
    ></event-addons>
  </div>
</template>
<script>
import TicketModel from "@/components/Ticket/TicketModel.vue";
import EventAddons from "@/views/Schedule/Event/EventAddons.vue";
import CustomerAlertNote from "@/views/Clients/Customer/CustomerAlertNote.vue";
export default {
  components: {
    TicketModel,
    EventAddons,
    CustomerAlertNote,
  },
  props: {
    show: { type: Boolean, default: false },
    event_id: { type: Number },
    event_name: { type: String },
    date: { type: String },
  },
  data() {
    return {
      totalPages: 0,
      page: 1,
      tickets: { id: null },
      ticketData: [],
      participantsDetails: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      addOnParticipants: [],
      openAddonModal: {
        show: false,
        orderId: null,
      },
      alertNote: {
        show: false,
        note: null,
        id: null,
      },
    };
  },

  mounted() {},

  watch: {
    page: {
      handler() {
        this.getEventParticipants();
      },
    },
    show(val) {
      if (val === true) {
        this.getEventParticipants();
      }
    },
  },

  methods: {
    checkInAndOut(id) {
      this.showLoader("Loading");
      this.$http
        .get(`venues/events/participants/check-in-and-out/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.getEventParticipants();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    openAlert(id) {
      this.showLoader();
      this.$http
        .get("venues/customers/get-alert-note/" + id)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.alertNote.id = response.data.data.customer_id;
            this.alertNote.note = response.data.data.alert_notes;
            this.alertNote.show = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    clearAlertNote(id) {
      this.showLoader();
      this.$http
        .post("venues/customers/clear-alert-note/" + id)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.showSuccess("Customer alert note cleared!");
            this.closeAlert();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    closeAlert() {
      this.alertNote.show = false;
      this.getEventParticipants();
    },
    viewAdditionalParticipants(orderId) {
      this.showLoader("Loading additional participants..");
      this.$http
        .get("venues/events/add-on-participants/" + orderId)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.addOnParticipants = response.data.data;
            this.openAddonModal.show = true;
            this.openAddonModal.orderId = orderId;
            // this.$forceUpdate();
            // this.$emit("close");
          } else {
            this.errorChecker("Something went wrong");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    closeAddonModal() {
      this.openAddonModal.show = false;
      this.openAddonModal.orderId = null;
    },
    getEventParticipants() {
      this.participantsDetails = [];
      this.showLoader("Loading participants..");
      this.$http
        .get("venues/events/logs/" + this.event_id + "?page=" + this.page)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.totalPages = response.data.total_pages;
            this.participantsDetails = response.data.data.filter(
              (x) =>
                (x.status == "Unpaid" ||
                  x.status == "Paid" ||
                  x.status == "Credit" ||
                  x.status == "Complementary") &&
                x.order_status_name != "Cancelled"
            );
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    confirmActions(data) {
      if (data.type == "eventCancel") {
        this.deleteBookings(data.id);
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },

    cancelEvent(id) {
      this.confirmModel = {
        id: id,
        title: `Cancel event?`,
        description: `Do you want to cancel event? By clicking <b>Yes</b> you can confirm cancelation`,
        type: "eventCancel",
      };
    },

    deleteBookings(id) {
      this.showLoader("Wait");
      this.$http
        .delete(`venues/orders/${id}`)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Event cancelled successfully");
            this.getEventParticipants();
            this.$emit("refresh");
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },

    viewTicket(index) {
      let data = this.participantsDetails[index];
      this.showLoader("Loading");
      this.$http
        .get(`venues/orders/${data.order_id}?event`)
        .then((response) => {
          this.ticketData = [];
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            let orderItems = response.data.data.items;
            orderItems.forEach((items) => {
              items.tickets.forEach((ticket) => {
                this.ticketData.push({
                  timestamp: response.data.data.updated_at,
                  ticket_code: ticket.ticket_code,
                  ticket_type: items.product.name,
                  order_date: this.date,
                  event_name: this.event_name,
                  id: String(ticket.id),
                });
              });
            });
            this.tickets = this.ticketData[0];
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    changeTickets(dir) {
      if (dir == "next") {
        this.ticketData.unshift(this.ticketData.pop());
        this.tickets = this.ticketData[0];
      } else {
        this.ticketData.push(this.ticketData.shift());
        this.tickets = this.ticketData[0];
      }

      this.$forceUpdate();
    },
  },
};
</script>
