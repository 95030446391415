<template>
  <v-dialog v-model="ticketDialoge" width="400" persistent>
    <v-card color="black">
      <slot name="carosal"></slot>
      <h2 class="headline_ticket pt-4 text-center">Q Portal</h2>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <div class="headTicket">Vendor</div>
              <div class="valueTicket">
                {{ venueName }}
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="headTicket">Purchase Date</div>
              <div class="valueTicket">
                {{ timestamp | timeStamp }}
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="headTicket">Booking Date</div>
              <div class="valueTicket">
                {{ order_date }}
              </div>
            </v-col>
          </v-row>

          <v-row v-if="service">
            <v-col>
              <div class="headTicket">Service</div>
              <div class="valueTicket">
                {{ service }}
              </div>
            </v-col>
          </v-row>

          <v-row v-if="event_name">
            <v-col>
              <div class="headTicket">Event</div>
              <div class="valueTicket">
                {{ event_name }}
              </div>
            </v-col>
          </v-row>

          <v-row v-if="ticket_type">
            <v-col>
              <div class="headTicket">Ticket Type</div>
              <div class="valueTicket">
                {{ ticket_type }}
              </div>
            </v-col>
          </v-row>

          <v-row v-if="ticket_code">
            <v-col>
              <div class="headTicket">Code</div>
              <div class="valueTicket">
                {{ ticket_code }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=" darken-1"
          class="ma-2 white--text blue-color"
          text
          @click="closeTicket"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    timestamp: { type: String, default: "" },
    type: { type: String, default: "GROUNDBOOKING" },
    id: { type: String, default: "" },
    start_time: { type: String, default: "" },
    end_time: { type: String, default: "" },
    quantity: { type: String, default: "" },
    ticket_type: { type: String, default: "" },
    order_date: { type: String, default: "" },
    service: { type: String, default: "" },
    location: { type: String, default: "" },
    name: { type: String, default: "" },
    ticket_code: { type: String, default: "" },
    event_name: { type: String },
  },
  data() {
    return {
      ticketDialoge: false,
    };
  },
  computed: {
    venueName() {
      return this.$store.getters.venueInfo.name;
    },
    venueCode() {
      return this.$store.getters.venueInfo.code.toUpperCase();
    },
  },
  watch: {
    id(val) {
      if (val != null && val != "") {
        this.ticketDialoge = true;
      } else {
        this.ticketDialoge = false;
        this.id = null;
      }
    },
  },
  methods: {
    closeTicket() {
      this.$emit("close");
      // this.ticketDialoge = false;
    },
  },
};
</script>

<style scoped>
.headline_ticket {
  background: #000;
  color: #fff;
}
.headTicket {
  color: gray;
  font-weight: bold;
}
.valueTicket {
  color: #fff;
  border-bottom: 1px solid gray;
}
</style>
