<template>
  <div>
    <v-dialog v-model="show" width="40%" scrollable persistent>
      <v-card>
        <v-toolbar color="teal" dark>
          <v-toolbar-title>Add on Participants</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-list subheader v-if="addOnParticipants.length > 0">
            <v-list-item
              v-for="participant in addOnParticipants"
              :key="participant.id"
            >
              <v-list-item-avatar>
                <view-image
                  defaultImage="user"
                  :image="participant.customer.profile_image"
                ></view-image>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >{{ participant.customer.first_name }}
                  {{ participant.customer.last_name }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action style="margin-left: 0px">
                <v-btn
                  v-show="
                    participant.check_in_time == null ||
                      participant.check_out_time == null
                  "
                  :color="
                    participant.check_in_and_out == 'OUT' ? 'error' : 'success'
                  "
                  @click="checkInAndOut(participant.id)"
                  class="ma-1 white--text teal-color"
                >
                  {{
                    participant.check_in_and_out
                      ? "Check " +
                        (participant.check_in_and_out == "OUT" ? "Out" : "In")
                      : "Check In/Out"
                  }}</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div
            v-else
            style="height: 200px"
            class="d-flex justify-center align-center"
          >
            No Additional Participants
          </div>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeModal" class="ma-2 white--text blue-color" text
            >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  //   props: ["participants", "show"],
  props: {
    addOnParticipants: {
      type: Array,
      default: function() {
        return [];
      },
    },
    show: { type: Boolean, default: false },
    orderId: { type: Number, default: null },
  },
  data() {
    return {};
  },

  watch: {
    // show(val) {
    //   if (val === true) {
    //     console.log("this.addOnParticipants");
    //     console.log(this.addOnParticipants);
    //   }
    // },
  },
  methods: {
    checkInAndOut(id) {
      this.showLoader("Loading");
      this.$http
        .get(`venues/events/add-on-participants/check-in-and-out/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.refreshModal();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    refreshModal() {
      this.$emit("refreshModal", this.orderId);
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
